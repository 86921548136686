@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-selfDriving-modal label {
    margin-bottom: rem(30);
    @include mq('md', 'max'){
        margin-bottom: rem(10);
    }
}

.hd-circles {
    background-image: linear-gradient(-122deg, $dark-grey-color, $dark-grey5-color);
    box-shadow: 0 rem(18) rem(57) rgba(black, 0.21);
    font-size: rem(13);
    line-height: normal;
    height: rem(320);
    width: rem(320);
    @include mq('md', 'max'){
        margin-bottom: rem(70);
    }
    &:after {
        font-size: rem(30);
        font-weight: bold;
        position: absolute;
        line-height: normal;
        right: rem(-6);
        @include mq('md', 'max'){
            right: auto;
            bottom: rem(12);
        }
    }
    &.hd-plus-after:after {
        content: '+';
    }
    &.hd-equal-after:after {
        content: '=';
    }
    &.gradient-circle {
        background-image: linear-gradient(-122deg, $primary-color, $purple-color);
        color: black;
    }
}