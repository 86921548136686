/*===== Classes start =====*/

.vh-100 {
    min-height: 100vh;
    height: auto !important;
}

.zi-1 {
    z-index: 1;
}

.no-list-style {
    list-style: none;
}

.dash-list-style {
    list-style: none;
    li:before {
        content: "-";
        padding-right: rem(5);
    }
}

.rect-green-list-style {
    list-style: none;
    li {
        position: relative;
        padding-left: rem(25);
        &:before {
            background-color: $primary-color;
            border-radius: rem(3);
            content: "";
            display: block;
            margin-right: rem(10);
            position: absolute;
            top: rem(4);
            left: 0;
            height: rem(15);
            width: rem(15);
        }
    }
}

.primary-text-color {
    color: $primary-color;
}

.grey-text-color {
    color: lighten($dark-grey-color, 12.5); 
}

.md-break-word {
    word-break: break-word;
}

/*===== Classes end =====*/