@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-blog-card-avatar {
    color: $purple-color;
    label {
        font-size: rem(15);
        line-height: normal;
    }
    p {
        font-size: rem(13);
        line-height: normal;
    }
}