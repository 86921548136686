/*===== Variables start =====*/

// Colors start
$primary-color: #00fa9a;
$grey-color: #eeeeee;
$dark-grey-color: #272727;
$dark-grey2-color: #6f6f6f;
$dark-grey3-color: #1c1c1c;
$dark-grey4-color: #e0e0e0;
$dark-grey5-color: #121212;
$dark-grey6-color: #1d1d1d;
$purple-color: #19122b;
$facebook-color: #225597;
$twitter-color: #1da0f2;
$google-color: #dc4937;
$danger-color: #f7284e;
$danger-shade-color: #af1c38;

// Typography
$font-default: 'Roboto Condensed', sans-serif;

// Break Pointss
$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
lgplus: 1025px,
xl: 1200px,
xxl: 1600px
) !default;

/*===== Variables end =====*/