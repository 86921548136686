@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

#products {
  background-color: $dark-grey-color;
  background-image: url("../../assets/images/section-solution-vector-bg.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 45%;
  position: relative;
  overflow: hidden;
  @include mq('lg', 'max'){
    background-position: left 100%;
    background-size: 100%;
    padding: rem(80) 0 rem(40);
  }
  &:before {
    background-image: url("../../assets/images/section-solution-before-vector.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    right: 0;
    top: rem(-50);
    height: rem(373);
    width: rem(147);
    @include mq('lg', 'max'){
      height: rem(270);
      width: rem(100);
    }
  }
  &:after {
    background-image: url("../../assets/images/section-solution-after-vector.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    right: rem(210);
    bottom: 0;
    height: rem(130);
    width: rem(469);
  }
  h1 {
    color: white;
  }
}

.md-solution-item {
  border-radius: rem(25);
  padding: rem(50) rem(15) rem(15);
  margin-top: rem(20);
  @include mq('lg', 'max'){
    padding: 0 5%;
    margin-top: rem(40);
  }
  &-avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: rem(20);
    height: rem(70);
    width: rem(70);
  }
  label {
    color: white;
    font-size: rem(18);
    &:after {
      background-color: $primary-color;
      border-radius: rem(3);
      content: "";
      display: block;
      margin: rem(15) auto rem(5);
      bottom: rem(-10);
      height: rem(4);
      width: rem(29);
    }
  }
  p {
    color: $dark-grey2-color;
    font-size: rem(12);
  }
  button {
    background-color: transparent;
    color: $dark-grey2-color;
    &:hover {
      background-color: $grey-color;
    }
    @include mq('lg', 'max'){
      background-color: white;
      color: $purple-color;
    }
  }
  &:hover {
    background-image: linear-gradient(-122deg, $primary-color, $purple-color);
    @include mq('lg', 'max'){
      background-image: none;
    }
    label {
      color: $purple-color;
      @include mq('lg', 'max'){
        color: white;
      }
      &:after {
        background-color: $purple-color;
        @include mq('lg', 'max'){
          background-color: $primary-color;
        }
      }
    }
    p {
      color: $purple-color;
      @include mq('lg', 'max'){
        color: $dark-grey2-color;
      }
    }
    button {
      background-color: white;
      color: $purple-color;
    }
  }
}

#md-solution-step1 {
  .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step1-icon.png");
    @include mq('lg', 'max'){
      background-image: url("../../assets/images/section-solution-step1-icon.png") !important;
    }
  }
  &:hover .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step1-hover.png");
  }
}

#md-solution-step2 {
  .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step2-icon.png");
    @include mq('lg', 'max'){
      background-image: url("../../assets/images/section-solution-step2-icon.png") !important;
    }
  }
  &:hover .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step2-hover.png");
  }
}

#md-solution-step3 {
  .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step3-icon.png");
    @include mq('lg', 'max'){
      background-image: url("../../assets/images/section-solution-step3-icon.png") !important;
    }
  }
  &:hover .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step3-hover.png");
  }
}

#md-solution-step4 {
  .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step4-icon.png");
    @include mq('lg', 'max'){
      background-image: url("../../assets/images/section-solution-step4-icon.png") !important;
    }
  }
  &:hover .md-solution-item-avatar {
    background-image: url("../../assets/images/section-solution-step4-hover.png");
  }
}
