@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.modal {
    padding-left: 0 !important;
    &-fluid {
        background-color: $dark-grey-color;
        background-image: url("../../assets/images/section-solution-vector-bg.png");
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 45%;
        position: relative;
        overflow: hidden;
        min-height: 100vh;
        margin: 0;
        padding: rem(80);
        max-width: 100%;
        width: 100%;
        @include mq('md', 'max'){
            padding: rem(40) rem(20) rem(20) !important;
        }
        .modal-content {
            background-color: transparent;
            border: 0;
            text-align: center;
        }
        .modal-header {
            border: 0;
            color: white;
            justify-content: center;
            padding: 0;
        }
        .modal-title {
            line-height: normal;
        }
        .modal-body {
            color: white;
            padding: rem(50) 0;
        }
        .modal-close {
            background-color: black;
            border: 0;
            color: white;
            opacity: 0.85;
            right: 0;
            height: rem(72);
            width: rem(72);
            @include mq('md', 'max'){
                font-size: rem(10);
                top: rem(-30);
                right: rem(-10) !important;
                height: rem(30) !important;
                width: rem(30) !important;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
}

.hd-modal-detail-bullet {
    label {
        font-size: rem(18);
        line-height: normal;
    }
    li {
        font-size: rem(16);
    }
    &-sm {
        label,
        li {
            font-size: rem(14);
        }
        a {
            color: white;
            &:hover {
                color: $primary-color;
            }
        }
    }
}