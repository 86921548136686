@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-featured-image {
    background-color: darken(white, 2);
    border-radius: rem(25);
    border: rem(1) dashed lighten($dark-grey6-color, 29.4);
    font-size: rem(13);
    height: rem(33);
    padding-left: rem(20);
    &:after {
        display: none;
    }
}
