@font-face {
font-family: '#{$icomoon-font-family}';
src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?u3apz7') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?u3apz7') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?u3apz7')
    format('svg');
font-weight: normal;
font-style: normal;
}

[class^='hd-icon-'],
[class*=' hd-icon-'],
.navbar-toggler {
/* use !important to prevent issues with browser extensions that change fonts */
font-family: '#{$icomoon-font-family}' !important;
speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;

/* Better Font Rendering =========== */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

// Icons
$hd-icon-facebook: '\e900';
$hd-icon-linkedin: '\e901';
$hd-icon-twitter: '\e902';
$hd-icon-youtube: '\e903';
$hd-icon-user: '\e904';
$hd-icon-mail: '\e905';
$hd-icon-phone: '\e906';
$hd-icon-close: '\e907';
$hd-icon-google: '\e908';
$hd-icon-pencil: '\e909';
$hd-icon-view: '\e90a';
$hd-icon-menu: '\e90b';

.hd-icon-facebook {
    &:before {
        content: $hd-icon-facebook;
    }
}

.hd-icon-linkedin {
    &:before {
        content: $hd-icon-linkedin;
    }
}

.hd-icon-twitter {
    &:before {
        content: $hd-icon-twitter;
    }
}

.hd-icon-youtube {
    &:before {
        content: $hd-icon-youtube;
    }
}

.hd-icon-user {
    &:before {
        content: $hd-icon-user;
    }
}

.hd-icon-mail {
    &:before {
        content: $hd-icon-mail;
    }
}

.hd-icon-phone{
    &:before {
        content: $hd-icon-phone;
    }
}

.hd-icon-google{
    &:before {
        content: $hd-icon-google;
    }
}

.hd-icon-close{
    &:before {
        content: $hd-icon-close;
    }
}

.hd-icon-pencil{
    &:before {
        content: $hd-icon-pencil;
    }
}

.hd-icon-view{
    &:before {
        content: $hd-icon-view;
    }
}

.navbar-toggler{
    &:before {
        content: $hd-icon-menu;
    }
}
