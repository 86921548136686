/*===== Global style start =====*/

// Default style start

html,
body {
  background-color: white;
  font-family: $font-default;
}

a,
button {
  outline: none !important;
}

.md-fluid-section {
  box-sizing: border-box;
  min-height: 100vh;
  &.md-default-section {
    background-repeat: no-repeat;
    overflow: hidden;
    &-left-bg {
      background-image: url('~assets/images/section-default-left-bg.png');
      background-position: left bottom;
      background-size: rem(130);
    }
    &-right-bg {
      background-image: url('~assets/images/section-default-right-bg.png');
      background-position: right bottom;
      background-size: rem(200);
    }
  }
  &.md-page-section {
    padding: rem(120) 0 rem(50);
    @include mq('lg', 'max'){
      padding-top: rem(75);
    }
  }
  &.md-text-center-mob {
    @include mq('lg', 'max'){
      text-align: center;
    }
  }
}

h1 {
  @include mq('lg', 'max'){
    font-size: rem(24) !important;
  }
}

br {
  @include mq('lg', 'max'){
    content: '';
    display: inline-block;
    width: rem(5);
  }
}

.container {
  @include mq-only('xs', 'lg'){
    max-width: 95%;
  }
}

/*===== Global style end =====*/
