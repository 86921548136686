/*===== Forms start =====*/

.hd-form-field {    
    .form-control {
        border-radius: rem(25);
        font-size: rem(13);
        padding-left: rem(20);
        outline: none !important;
    }
    textarea {
        border-radius: rem(15);
        padding: rem(15);
        height: calc(100% - 26px);
        resize: none;
        @include mq('md', 'max'){
            height: rem(120);
        }
    }
    &.hd-field-icon {
        position: relative;
        i {
            position: absolute;
            left: rem(15);
            top: rem(9);
        }
        .form-control {
            padding-left: rem(40);
        }
    }
    &.hd-dark-field {
        .form-control {
            background-color: $dark-grey3-color;
            border-color: $dark-grey3-color;
            box-shadow: 0 rem(18) rem(57) rgba(black, 0.21);
            color: white;
            &:focus {
                border-color: $dark-grey2-color;
            }
        }
        i {
            color: $dark-grey2-color;
        }
    }
    &.hd-light-field {
        .form-control {
            background-color: white;
            border-color: white;
            box-shadow: 0 rem(7) rem(24) rgba(black, 0.10);
            color: black;
            &:focus {
                border-color: $dark-grey4-color;
            }
        }
        label {
            margin-bottom: rem(10);
        }
    }
    &.hd-no-label label {
        display: none;
    }
    &.hd-bottom-border-field {
        .form-control {
            border-width: 0 0 rem(1);
            border-radius: 0;
            color: $dark-grey6-color;
            font-weight: bold;
            padding: rem(10) 0 rem(10);
        }
    }
}

/*===== Forms end =====*/