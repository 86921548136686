@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-profile-avatar {
  @include mq("lg", "max") {
    text-align: center;
    margin-bottom: rem(20);
  }
  h3 {
    font-size: rem(24);
  }
  &-control {
    background-color: darken(white, 2);
    border: rem(1) dashed darken($grey-color, 12.9);
    border-radius: rem(20);
    height: rem(80);
    width: rem(80);
    @include mq("lg", "max") {
      margin: 0 auto;
    }
    i {
      color: darken($grey-color, 12.9);
      font-size: rem(24);
    }
    &.md-avatar-available {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border: 0;
    }
  }
}

.custom-file {
  @include mq("lg", "max") {
    justify-content: center;
  }
}