@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-editor-wrapper {
  background-color: white;
  border-color: white;
  box-shadow: 0 rem(7) rem(24) rgba(black, 0.10);
  border-radius: rem(25);
  padding: 0;
  height: rem(248);
  @include mq('md', 'max'){
    height: rem(300);
  }
}

.md-editor-toolbar {
  border-radius: rem(25) rem(25) 0 0 !important;
  border: 0 !important;
  padding: rem(10) rem(10) 0 !important;
  margin: 0 !important;
}

.md-editor-field {
  padding: rem(13) rem(15) rem(15);
  line-height: rem(18);
  height: calc(100% - 75px) !important;
  .public-DraftStyleDefault-block {
    margin:0;
  }
}
