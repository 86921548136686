@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

#bullets {
    top: 45%;
    right: rem(30);
    z-index: 1;
    @include mq('lg', 'max'){
        display: none !important;
    }
    a {
        background-color: $dark-grey4-color;
        border-radius: rem(25);
        height: rem(7);
        width: rem(7);
        &.active {
            background-color: $primary-color;
            height: rem(11);
            width: rem(11);
        }
        &:hover {
            background-color: $primary-color;
        }
        &:not(:last-child) {
            margin-bottom: rem(6);
        }
    }
}