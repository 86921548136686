@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-tag-select__control {
    border-color: white !important;
    box-shadow: 0 rem(7) rem(24) rgba(black, 0.10);
    border-radius: rem(25) !important;
    color: black;
    font-size: rem(13);
    padding: rem(10) 0 rem(10) rem(20);
    height: rem(75);
    &:focus {
        border-color: $dark-grey4-color !important;
    }
    .md-tag-select__value-container {
        align-items: flex-start;
        padding: 0;
        height: 100%;
        .md-tag-select__placeholder {
            top: 25%;
        }
    }
    .md-tag-select__multi-value {
        background-color: $primary-color;
        border-radius: rem(10);
        .md-tag-select__multi-value__remove {
            border-radius: 0 rem(10) rem(10) 0;
            &:hover {
                background-color: darken($primary-color, 7);
                color: black;
            }
        }
    }
    span {
        display: none !important;
    }
}