/*===== Buttons start =====*/

.hd-btn {
    background-color: $grey-color;
    border: 0;
    border-radius: rem(25);
    color: black;
    font-size: rem(14);
    font-weight: 700;
    height: rem(38);
    outline: none !important;
    min-width: rem(149);
    &-small {
        height: rem(34);
        min-width: rem(119);
    }
    &-large {
        font-size: rem(15);
        min-width: rem(176);
        height: rem(50);
        @include mq('lg', 'max'){
            font-size: rem(14);
            min-width: rem(149);
            height: rem(38);
        }
    }
    &:not(:last-child) {
        margin-right: rem(20);
    }
    &:hover {
        background-color: darken($grey-color, 3.9);
    }
    &.hb-facebook-btn {
        background-color: $facebook-color;
        &:hover {
            background-color: lighten(desaturate($facebook-color, 6.2), 3.9);
        }
    }
    &.hb-twitter-btn {
        background-color: $twitter-color;
        &:hover {
            background-color: lighten(saturate($twitter-color, 8.1), 3.9);
        }
    }
    &.hb-google-btn {
        background-color: $google-color;
        &:hover {
            background-color: lighten(saturate($google-color, 6.5), 3.9);
        }
    }
}

.hd-btn-primary {
    background-color: $primary-color;
    &:hover {
        background-color: lighten(adjust-hue($primary-color, 0.8), 2.9);
    }
}

.hd-btn-primary-shadow {
    box-shadow: 0 rem(18) rem(29) rgba($primary-color, 0.57);
}

.hd-btn-primary-hover:hover {
    background-color: $primary-color;
    @extend .hd-btn-primary-shadow;
}

.hd-btn-dark {
    background-image: linear-gradient($dark-grey-color, $purple-color);
    color: white;
    &:hover {
        background-image: linear-gradient($purple-color, $purple-color);
    }
}

.hd-btn-danger {
    background-image: linear-gradient($danger-color, $danger-shade-color);
    color: white;
    &:hover {
        background-image: linear-gradient($danger-color, $danger-color);
    }
}

.hd-btn-circle {
    border-radius: 50%!important;
    border: 0;
    font-size: rem(14);
    height: rem(35);
    width: rem(35);
    &:not(:last-child) {
        margin-right: rem(10);
    }
}

/*===== Buttons end =====*/