@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-blog-card {
    box-shadow: 0 rem(9) rem(25) rgba(black, 0.13);
    border-radius: rem(25);
    overflow: hidden;
    &-img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        height: rem(160);
        padding: rem(10);
    }
    .card-body {
        padding: rem(15) rem(20) rem(25);
        height: rem(180);
    }
    .card-title {
        color: $purple-color;
    }
    .card-text {
        color: $purple-color;
        font-size: rem(12);
        line-height: normal;
    }
}