@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-blog-detail {
    &-banner {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        height: rem(400);
        @include mq('md', 'max'){
            height: rem(250);
        }
        &-title {
            background-color: rgba(white, 0.7);
            height: rem(140);
            padding: 0 rem(10);
            @include mq('md', 'max'){
                height: rem(100);
            }
        }
    }
}

.md-share {
    @include mq('md', 'max'){
        margin-top: rem(30);
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            color: $purple-color;
            font-size: rem(16);            
            & + li {
                margin-left: rem(20);
            }
            a {
                color: darken($dark-grey-color, 1.2);
                font-size: rem(14);
                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

.md-detail-desc {
    border-top: rem(1) solid lighten($dark-grey4-color, 5.1);
    span {
        font-family: $font-default !important;
    }
}