@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

#solutions {
  background-image: url("../../assets/images/section-product-vector-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  overflow: hidden;
  @include mq('lg', 'max'){
    padding: rem(40) 0;
  }
  h1 {
    font-size: rem(26);
    line-height: rem(38);
  }
  small {
    font-size: rem(16);
    line-height: rem(22);
  }
}

.md-product-item {
  background-color: white;
  border-radius: rem(25);
  box-shadow: 0 rem(9) rem(25) rgba(black, 0.13);
  padding: rem(20) rem(35) rem(15);
  margin-bottom: rem(30);
  p {
    font-size: rem(12);
    line-height: normal;
    margin-bottom: rem(10);
  }
  .hd-btn {
    @include mq('lg', 'max'){
      background-color: $primary-color;
      box-shadow: 0 rem(18) rem(29) rgba($primary-color, 0.57);
    }
  }
}
