@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.navbar {
    padding: rem(30) 0;
    @include mq('lg', 'max'){
        padding: rem(15) 0;
    }
}

.hd-logo {
    color: black;
    font-size: rem(27);
    img {
        margin-right: rem(15);
        height: rem(50);
        width: rem(50);
        @include mq('lg', 'max'){
            margin-right: rem(10);
            height: rem(35);
            width: rem(35);
        }
    }
}

.hd-nav {
    .navbar-toggler {
        border: 0;
        padding-right: 0;
        .navbar-toggler-icon {
            display: none !important;
        }
    }
    .navbar-collapse {
        @include mq('lg', 'max'){
            background-color: white;
            box-shadow: 0 rem(7) rem(24) rgba(black, 0.10);
            border-radius: rem(4);
            position: absolute;
            left: 0;
            top: rem(63);
            overflow: hidden;
            width: 100%;
        }
    }
    .navbar-nav {
        a {
            color: black !important;
            font-size: rem(16);
            text-decoration: none;
            position: relative;
            padding: 0 !important;
            @include mq('lg', 'max'){
                font-size: rem(14);
                padding: rem(5) !important;
            }
            &:before {
                background-color: $primary-color;
                border-radius: rem(3);
                content: '';
                display: none;
                margin: 0 auto;
                position: absolute;
                bottom: rem(-10);
                left: 0;
                right: 0;
                height: rem(4);
                width: rem(18);
                @include mq('lg', 'max'){
                    display: none !important;
                }
            }
            &:after {
                display: none;
            }
            &:hover:before,
            &.active:before {
                display: block;
            }
            &.active {
                @include mq('lg', 'max'){
                    border-left: rem(5) solid $primary-color;
                }
            }
        }
        .nav-item:not(:last-child) {
            margin-right: rem(30);
            @include mq('lg', 'max'){
                border-bottom: rem(1) solid darken($grey-color, 0.4);
                margin-right: 0;
            }
        }
    }
    .dropdown {
        &.active:before {
            background-color: $primary-color;
            border-radius: rem(3);
            content: '';
            margin: 0 auto;
            position: absolute;
            bottom: rem(-10);
            left: 0;
            right: 0;
            height: rem(4);
            width: rem(18);
            @include mq('lg', 'max'){
                display: none;
            }
        }
        &.hd-blog-center .dropdown-menu {
            left: -220%;
        }
    }
    .dropdown-menu {
        box-shadow: 0 rem(7) rem(24) rgba(black, 0.10);
        border-radius: rem(10);
        border: 0;
        padding: 0;
        top: rem(45);
        overflow: hidden;
        .dropdown-item {
            &:not(:last-child) {
                border-bottom: rem(1) solid darken($grey-color, 0.4);
            } 
        }
        .nav-item {
            background-color: white;
            border: 0;
            border-left: rem(2) solid white;
            color: black;
            display: block;
            font-size: rem(13);
            text-align: left;
            text-decoration: none;
            padding: rem(8) rem(13) !important;
            width: 100%; 
            &:hover {
                background-color: lighten($dark-grey4-color, 5.1);
                border-left-color: lighten($dark-grey4-color, 5.1);
            }
            &.active {
                border-left-color: $primary-color;
            }
            &:not(:last-child) {
                border-bottom: rem(1) solid darken($grey-color, 0.4);
            } 
        }
    }
}

.hd-nav-wrap {
    background-image: linear-gradient(white, white, rgba(white, 0));
    &.hd-light-nav {
        background-image: linear-gradient($dark-grey-color, $dark-grey-color, rgba($dark-grey-color, 0));
        .hd-nav .navbar-nav .dropdown-menu a {
            color: black !important;
        }
        .hd-logo a,
        .hd-nav .navbar-nav a,
        .navbar-toggler {
            color: white !important;
        }
        .hd-nav .navbar-nav a {
            @include mq('lg', 'max'){
                color: black !important;
            }
        }
    }
}
