@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.modal-auth {
    .modal-content {
        border-radius: rem(20);
        padding: rem(50);
        @include mq('md', 'max'){
            padding: rem(20);
        }
    }
    .modal-header {
        padding: 0;
        border: 0;
    }
    .modal-title {
        line-height: normal;
    }
    .modal-body {
        a {
            color: $dark-grey6-color;
            font-size: rem(13);
            &.hd-anchor-md {
                font-size: rem(15);
                text-decoration: underline;
            }
        }
    }
    .modal-close {
        background-color: #e9e9e9;
        border: 0; 
        color: black;
        font-size: rem(12);
        opacity: 0.85;
        top: rem(30);
        right: rem(30);
        height: rem(44);
        width: rem(44);
        @include mq('md', 'max'){
            font-size: rem(10);
            top: rem(19);
            right: rem(19);
            height: rem(30);
            width: rem(30);
        }
        &:hover {
            opacity: 1;
        }
    }
}

.hd-social-auth {
    label {
        font-size: rem(15);
        font-weight: bold;
    }
    .hd-btn {
        color: white;
        font-size: rem(12);
        font-weight: 100;
        min-width: 0;
        width: calc(33% - 12px);
        i {
            font-size: rem(14);
            margin-right: rem(5);
        }
    }
}

.md-terms-message {
    font-size: rem(13);
    font-weight: bold;
    a {
        color: $twitter-color !important;
    }
}

.md-center-form {
    label {
        font-size: rem(12);
    }
    .hd-form-field input {
        font-size: rem(24);
        text-align: center;
    }
}