@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.modal {
    padding-left: 0 !important;
    &-solution-modal {
        background-color: white;
        background-image: url("../../assets/images/solution-modal-bg.jpg");
        background-repeat: no-repeat;
        background-position: right top;
        position: relative;
        overflow: hidden;
        min-height: 100vh;
        margin: 0;
        padding: rem(80);
        max-width: 100%;
        width: 100%;
        @include mq('md', 'max'){
          padding: rem(40) rem(20) rem(20) !important;
        }
        .modal-content {
            background-color: transparent;
            border: 0;
            text-align: center;
        }
        .modal-header {
            border: 0;
            color: black;
            padding: 0;
        }
        .modal-title {
            line-height: normal;
        }
        .modal-body {
            color: black;
            padding: rem(50) 0;
        }
        .modal-close {
            background-color: white;
            border: 0;
            color: black;
            opacity: 0.95;
            right: 0;
            height: rem(72);
            width: rem(72);
            z-index: 1;
            @include mq('md', 'max'){
              font-size: rem(10);
              top: rem(-30);
              right: rem(-10) !important;
              height: rem(30) !important;
              width: rem(30) !important;
            }
            &:hover {
                opacity: 1;
            }
        }
        &:before {
            background-image: url('../../assets/images/section-default-left-bg.png');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: rem(150);
            width: rem(150);
        }
    }
}
