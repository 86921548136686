@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

#home {
  background-image: url('../../assets/images/section-home-vector-bg.png');
  background-repeat: no-repeat;
  background-position: 85% bottom;
  background-size: 65%;
  position: relative;
  overflow: hidden;
  @include mq('lg', 'max'){
    background-position: right 100%;
    background-size: 100%;
  }
  &:before {
    background-image: url('../../assets/images/section-home-before-vector.png');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    left: 0;
    top: rem(40);
    height: rem(347);
    width: rem(120);
  }
  &:after {
    background-image: url('../../assets/images/section-home-after-vector.png');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    right: 0;
    top: 27%;
    height: 50%;
    width: 50%;
    @include mq('lg', 'max'){
      top: 12%;
      height: 90%;
      width: 90%;
    }
    @include mq('md', 'max'){
      height: 100%;
      width: 100%;
    }
  }
  .hd-person-img {
    @include mq('lg', 'max'){
      margin-bottom: rem(50);
      width: 50% !important;
    }
  }
  @include mq('sm', 'max'){
    .hd-btn-large {
      min-width: 7.3125rem;
    }
  }
}
