@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

.md-profile-dropdown {
    .dropdown-toggle {
        background-color: darken(white, 2) !important;
        border: rem(1) solid darken($grey-color, 12.9) !important;
        box-shadow: none !important;
        margin-left: rem(30);
        height: rem(40);
        width: rem(40);
        &:after {
            display: none;
        }
        i {
            color: darken($grey-color, 12.9);
            font-size: rem(14);
        }
        &.md-avatar-available {
            background-color: transparent !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border: 0 !important;
        }
        &:hover {
            border-color: $primary-color !important;
        }
    }
    .dropdown-menu {
        top: rem(53);
        .dropdown-item {
            padding: 0;
        }
    }
}
