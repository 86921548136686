@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

#contact {
    background-image: url('../../assets/images/section-company-vector-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 66%;
    overflow: hidden;
    @include mq('lgplus', 'min'){
        background-position: right rem(95);
        background-size: 65%;
    }
    @include mq('xl', 'min'){
        background-position: right rem(55);
    }
    @include mq('xxl', 'min'){
        background-position: right rem(60);
    }
    @include mq('lgplus', 'max'){
        background-image: none !important;
        padding: rem(40) 0 0;
    }
    p {
        font-size: rem(20);
        line-height: normal;
    }
    label {
        font-size: rem(22);
        line-height: normal;
    }
    strong {
        font-size: rem(24);
    }
    .mb-5 {
        @include mq('lgplus', 'max'){
            margin-bottom: 0 !important;
        }
    }
    .container {
        @include mq('lgplus', 'max'){
            max-width: 100%;
        }
    }
    .company-left {
        @include mq('lgplus', 'max'){
            text-align: center;
            flex-basis: 100%;
            max-width: 100%;
        }
        @include mq('lg', 'max'){
            margin: 0 2.5%;
        }
    }
    .company-right {
        @include mq('lgplus', 'max'){
            margin: 0 2.5%;
            padding-top: rem(120);
        }
        @include mq('md', 'max'){
            padding-top: rem(100);
        }
        @include mq('sm', 'max'){
            padding-top: rem(30);
        }
    }
    &.md-text-center-mob {
        @include mq('lgplus', 'max'){
            text-align: center;
        }
    }
}

.hd-social {
    ul {
        @include mq('lgplus', 'max'){
            justify-content: center !important;
        }
    }
    li:not(:last-child) {
        margin-right: rem(25);
    }
    a {
        color: black;
        &:hover {
            color: $primary-color;
        }
    }
}

.hd-footer-nav {
        margin-top: rem(210);
        @include mq('lgplus', 'max'){
            margin-top: rem(280);
        }
        @include mq('sm', 'max'){
          margin-top: rem(150);
        }
    li {
        display: inline-block;
        line-height: normal;
        margin-bottom: rem(15);
        width: calc(100% / 3);
        a {
            color: white;
            font-size: rem(14);
            text-decoration: none;
            position: relative;
            &:after {
                background-color: $primary-color;
                border-radius: rem(3);
                content: '';
                display: none;
                margin: 0 auto;
                position: absolute;
                bottom: rem(-10);
                left: 0;
                right: 0;
                height: rem(4);
                width: rem(18);
            }
            &:hover:after,
            &.active:after {
                display: block;
            }
        }
    }
}

.hd-contact-form label {
    color: white;
}

.hd-footer-bg-mob {
    @include mq('lgplus', 'max'){
        background: url('../../assets/images/section-company-vector-mob-bg.png');
        background-color: $dark-grey5-color;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}

.hd-copyright-web {
    @include mq('lgplus', 'max'){
        display: none !important;
    }
}

.hd-copyright-mob {
    @include mq('lgplus', 'max'){
        display: block !important;
    }
}
