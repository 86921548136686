@import "../../assets/scss/helpers/variables";
@import "../../assets/scss/helpers/mixins";

[class*="ReactSlackChat__card"] {
  border-radius: rem(25) rem(25) 0 0;
  z-index: 99;
  @include mq('lg', 'max'){
    border-radius: 50%;
    height: rem(50) !important;
    right: rem(10) !important;
    bottom: rem(10) !important;
    width: rem(50) !important;
  }
  [class^="ReactSlackChat__helpHeader"] {
    background-image: linear-gradient(
      90deg,
      $primary-color,
      adjust-hue($primary-color, 11)
    );
    border-radius: rem(25) rem(25) 0 0;
    opacity: 1 !important;
    visibility: visible !important;
    @include mq('lg', 'max'){
      border-radius: 50%;
      display: flex;
      align-items: center;
      height: rem(50);
      width: rem(50);
    }
    [class^="ReactSlackChat__subText"] {
      display: none;
    }
  }
  [class^="ReactSlackChat__transition"] {
    color: black !important;
    font-size: rem(22) !important;
    font-weight: bold !important;
    @include mq('lg', 'max'){
      font-size: 0 !important;
      margin: 0;
      &:before {
        content: '?';
        font-size: rem(22) !important;
        font-weight: bold !important;
      }
    }
  }
}

[class*="ReactSlackChat__active"] {
  border-radius: rem(25) rem(25) 0 0;
  bottom: 0;
  height: 500px !important;
  width: 340px !important;
  @include mq('sm', 'max'){
    width: 300px !important;
  }
  [class^="ReactSlackChat__helpHeader"] {
    border-radius: rem(25) rem(25) 0 0 !important;
    padding: rem(8) rem(22);
    height: rem(31);
    width: 100%;
  }
  [class^="ReactSlackChat__transition"] {
    font-size: rem(13) !important;
    text-align: left;
    &:before {
      display: none;
    }
  }
}

[class*="ReactSlackChat__chat"] {
  [class*="ReactSlackChat__chatHeader"] {
    background-color: white;
    border-bottom: rem(1) solid $grey-color;
    height: rem(52);
    top: rem(31);
    [class*="ReactSlackChat__channel__close__button"] {
      color: black;
      top: rem(-28);
      right: rem(11);
      font-size: rem(16);
    }
    [class*="ReactSlackChat__chat__back"] {
      &:before {
        top: rem(18);
        left: rem(14);
        height: rem(14);
        width: rem(14);
      }
    }
    [class*="ReactSlackChat__chat__person"] {
      left: rem(20);
      top: rem(6);
      [class*="ReactSlackChat__chat__status"] {
        color: black;
        font-size: rem(13);
        left: rem(36);
        font-weight: 600;
      }
      [class*="ReactSlackChat__chat__name"] {
        color: $dark-grey2-color;
        font-size: rem(10);
      }
      [class*="ReactSlackChat__chat__online"] {
        border-radius: rem(4);
        left: rem(23);
        top: rem(7);
        height: rem(8) !important;
        width: rem(8) !important;
      }
    }
    [class*="ReactSlackChat__channel__header__photo"] {
      height: rem(38);
      right: rem(10);
      top: rem(7);
    }
  }
}

[class*="ReactSlackChat__chat__messages"] {
  top: rem(83);
  height: 75%;
  width: 100%;
  [class*="ReactSlackChat__chat__msgRow"] {
    [class*="ReactSlackChat__chat__contact__photo"] {
      height: rem(24);
    }
    [class*="ReactSlackChat__chat__message"] {
      border-radius: rem(15);
      font-family: $font-default;
      font-size: rem(14);
      font-weight: 600;
      padding: rem(8) rem(15);
    }
  }
  [class*="ReactSlackChat__mine"] {
    [class*="ReactSlackChat__chat__message"] {
      background-color: lighten($dark-grey-color, 8.6);
      color: white;
    }
  }
}

[class*="ReactSlackChat__chat__input"] {
  background-color: white;
  border: 0;
  border-top: rem(1) solid $grey-color;
  font-size: rem(12);
  padding: rem(10) rem(45) rem(10) rem(10);
  width: 100%;
}

[class*="ReactSlackChat__attachmentIcon"] {
  right: rem(10);
  left: auto;
  bottom: rem(8);
  margin: 0;
}
